<template>
  <div>

    <HeaderMain />

    <!-- КАК ПОЛУЧИТЬ ЗАЁМ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: var(--accent);">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Как получить заём<br>на банковскую<br>карту</h1>
                  <h1 class="text-mini white" style="color: #fff; text-align: right;">Деньги у вас уже<br>в 13:40</h1>
                </div>
              </div>

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="hidden-install">
                <div class="w-layout-vflex flex-good"
                     style="
                       margin-bottom: 20px;
                       align-items: end;
                       justify-content: flex-start;
                       flex-flow: row;"
                >
                  <div>
                    <img height="30px" alt="visa" src="../../../../../public/images/visa.svg">
                  </div>
                  <div>
                    <img height="30px" alt="mir" src="../../../../../public/images/mir.svg">
                  </div>
                  <div>
                    <img height="30px" alt="mastercard" src="../../../../../public/images/mastercard.svg">
                  </div>
                </div>

                <div class="w-layout-vflex flex-good justify-content-start"
                     style="flex-flow: row; margin-bottom: 20px;"
                >
                  <div>
                    <img height="100%" alt="mastercard" src="../../../../../public/images/cards2.svg">
                  </div>
                  <h1 class="text-mini white" style="color: #fff; margin-top: auto;">Получение на карту<br>1-5 минут</h1>
                </div>
              </div>

              <div class="w-layout-vflex flex-good "
                   style="
                       overflow: initial;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-between;"
              >
                <div class="install-image">
                  <div class="w-layout-vflex flex-good"
                       style="flex-flow: row;"
                  >
                    <div>
                      <img height="30px" alt="visa" src="../../../../../public/images/visa.svg">
                    </div>
                    <div>
                      <img height="30px" alt="mir" src="../../../../../public/images/mir.svg">
                    </div>
                    <div>
                      <img height="30px" alt="mastercard" src="../../../../../public/images/mastercard.svg">
                    </div>
                  </div>
                </div>
                <div>
                  <img
                      style="transform: translate(50px, 80px); margin-top: -100px;"
                      class="w-100 iphone"
                      alt="phone" src="../../../../../public/images/cards.png">
                </div>
                <div style="width: 40%; max-width: 300px;" class="install-image">
                  <div class="w-layout-vflex flex-good justify-content-end"
                       style="flex-flow: row;"
                  >
                    <h1 class="text-mini white" style="color: #fff; text-align: end; margin-top: auto;">Получение на карту<br>1-5 минут</h1>
                    <div>
                      <img height="100%" alt="mastercard" src="../../../../../public/images/cards2.svg">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- МГНОВЕННЫЙ ЗАЁМ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81"
           class="container one w-container pt-0 pb-0"
           style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: var(--green-50);
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Деньги на карту</div>
              <div class="w-100">
                <h1 class="heading">Как получить заём</h1>
              </div>
            </div>

            <div class="w-layout-hflex flex-item-best pa-0">
              <a id="openLabel" @click="goToPersonalData" class="button-bust w-button">Оформить заявку</a>
            </div>
          </div>
          <!-- инструкция для одобрения займа -->
          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right"
               style="padding: 25px 25px 35px;">
            <div class="text-mini semibold">Инструкция</div>
            <h2 class="heading h2">Для одобрения займа необходимо сделать следующие шаги:</h2>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/1.svg" alt="number1">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Выберите срок и сумму займа.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/2.svg" alt="number2">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">В процессе оформления займа выберите способ получения «Банковская карта».</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/3.svg" alt="number3">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Добавьте вашу банковскую карту.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/4.svg" alt="number4">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Мы должны убедиться, что карта принадлежит именно вам. Для этого проводится простая процедура верификации — небольшая сумма блокируется, а потом моментально возвращается на счет.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/5.svg" alt="number5">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Подпишите договор займа с помощью кода из SMS.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/6.svg" alt="number5">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Деньги поступят на карту в течение нескольких минут.</h2>
              </div>
            </div>
          </div>
        </div>

        <hr class="bold mx-25" style="margin-top: 30px; margin-bottom: 30px;">

      </div>
    </div>

    <!-- ПЛЮСЫ ПОЛУЧЕНИЯ ЗАЙМА -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0 pb-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">

          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Преимущества</div>
              <h2 class="heading h2">Плюсы получения займа<br>на банковскую карту:</h2>

              <div>
                <p class="text-mini white">Это самый быстрый способ зачисления средств. Деньги поступят моментально.</p>
                <p class="text-mini white">Карта может принадлежать любому российскому банку.</p>
                <p class="text-mini white">Принимаются карты любого типа: VISA, MasterCard, VISA Electron, Maestro, МИР.</p>
                <p class="text-mini white">Карта может быть как дебетовой, так и кредитной.</p>
                <p class="text-mini white">Получение займа происходит полностью онлайн.</p>
                <p class="text-mini white">Безопасный способ оплаты. Все денежные операции легко отслеживать и контролировать.</p>
                <p class="text-mini white">Картой легко расплатиться как на кассе в магазине, так и при покупках в Интернете.</p>
              </div>

            </div>
          </div>

          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right w-100"
               style="padding: 25px 25px 35px;">

            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Важная информация</div>
              <h2 class="heading h2">Обратите внимание</h2>

              <div>
                <p class="text-mini white">Карта должна быть выпущена на имя клиента.</p>
                <p class="text-mini white">Убедитесь, что у карты не истек срок действия.</p>
                <p class="text-mini white">Банковская карта может представлять собой как физическую (пластиковую)
                  карту, так и электронный вариант (например, виртуальную карту, которую можно использовать онлайн).
                </p>
              </div>

            </div>

            <div class="w-layout-hflex flex-item-best w-text" style="gap: 20px;">
              <a @click="goToHelp" class="button-link">Помощь</a>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!-- КОЛОБОК В ТВОЁМ ТЕЛЕФОНЕ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: linear-gradient(to bottom right, #6F7B8F, #202329);">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Колобок в твоём<br>телефоне</h1>
                  <h1 class="text-mini white" style="color: #fff;">Установите наше приложение<br>и получите деньги за пару кликов</h1>
                </div>
              </div>

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="w-layout-vflex flex-good hidden-install"
                   style="
                       margin-bottom: 60px;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-around;"
              >
                <div>
                  <img height="60px" alt="googleplay" src="../../../../../public/images/google_play.svg">
                </div>
              </div>

              <div class="w-layout-vflex flex-good "
                   style="
                       overflow: initial;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-between;"
              >
                <div class="install-image">
                  <img height="60px" alt="ruble" src="../../../../../public/images/ruble.svg">
                </div>
                <div>
                  <img
                      style="transform: translate(50px, 80px); margin-top: -100px;"
                      class="w-100 iphone"
                      alt="phone" src="../../../../../public/images/iphone.png">
                </div>
                <div style="width: 40%; max-width: 300px; text-align: right;" class="install-image">
                  <img style="max-width: 300px;" alt="install" src="../../../../../public/images/google_play.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'Main',
  data() {
    return {
    };
  },
  components: {
    Footer,
    HeaderMain
  },
  created() {
  },
  computed: {
  },
  methods: {
    goToHelp() {
      this.$router.push('/support');
    },
    goToPersonalData() {
      this.$router.push('/personal-data');
    },
    jqueryLoaded() {
    },
    reloadComponent() {
      this.$router.go(0);
    },
    goToLogin() {
      this.$router.push('/login');
    },
    goToReg() {
      this.$router.push('/registration-step1');
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                  this.jqueryLoaded();

                  // Call the function initially to set the time
                  this.renderTime();
                  // Set an interval to update the time every 10 minutes
                  setInterval(this.renderTime, 1000);
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    },
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
